.App {
  text-align: center;
}

.App-logo {
  height: 20px;
  pointer-events: none;
  position: sticky;
  bottom: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

a {
  color: inherit;
}

.App-logo-spin {
  animation: App-logo-spin 3s ease infinite;
}
