@keyframes load {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gf__layout {
  animation: load 0.2s ease-in;
  z-index: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  position: relative;
}
