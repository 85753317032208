.gf__footer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
  border-top: 1px solid lightgrey;
}

.gf__footer ul {
  padding: 0 20px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.gf__footer li {
  padding: 5px 10px;
  word-wrap: never;
  min-width: fit-content;
}

.gf__footer a {
  text-decoration: none;
  text-transform: uppercase;
}

.gf__footer p {
  text-align: center;
}

@media (max-width: 480px) {
  .gf__footer ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gf__footer li,
  .gf__footer p {
    display: none;
  }

  .gf__footer li:nth-child(1),
  .gf__footer:hover li,
  .gf__footer:hover p {
    display: unset;
  }

  .gf__footer li {
    text-align: center;
  }

  .gg-chevron-down {
    box-sizing: border-box;
    position: relative;
    display: inline;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
  }
  .gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }
}

@keyframes pump {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.gf__footer__pump {
  color: #eb5e28;
  animation: pump 1s ease infinite;
}

.gf__footer__wrp--xs {
  display: flex;
}
