@keyframes loading {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

.gf__splash__logo {
  animation: loading 2.5s ease infinite;
}
