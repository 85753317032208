body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gf__splash {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

@keyframes loading {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

.gf__splash__logo {
  animation: loading 2.5s ease infinite;
}

.gf__logo__text {
  font-weight: 900;
  font-size: 2em;
  font-family: "Monospace", sans-serif;
  line-height: 3em;
  letter-spacing: 1em;
  text-transform: uppercase;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
strong,
b {
  font-weight: 700;
}
body,
.gf__layout {
  /* color: #fff; */
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  /* background-color: #303030; */
}

/*
@media print {
    body {
        background-color: #fff;
    }
}
body::backdrop {
    background-color: #303030;
}

*/
